<template>
  <div>
    <img style="width:100%" @click="add" src="../../assets/images/market/fxqgl.png" alt="">
  </div>
</template>

<script>
  export default {
    methods: {
      add() {
        // this.$router.push('/addAssess')
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>